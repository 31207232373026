import { useContext } from 'react';
import { Link } from "react-router-dom";
import NavsLink from "./NavsLink"
import { Container } from "react-bootstrap";
import * as FaIcons from 'react-icons/fa';

import { GlobalContext } from "../data/GlobalContext";



const Header = () => {
    const dbData = useContext(GlobalContext);
    return (
        <div className="main-header__two">
            <div className="main-header__top">
                <Container>
                    <p>Welcome to {dbData.dbPrincipal.name}</p>
                    <div className="main-header__social">
                        {
                            dbData.dbSocialMedia.redes.map((item, index) => {
                                const Social =
                                    FaIcons[
                                    item.icon
                                    ];
                                return (
                                    <a
                                        key={index}
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Social />
                                    </a>
                                )
                            })
                        }
                    </div>
                </Container>
            </div>
            <div className="header-upper">
                <Container>
                    <div className="logo-box">
                        <Link to='/'>
                            <img src={`${dbData.dbPrincipal.logo}`} width="250" alt="logo" />
                        </Link>
                        <span className="fa fa-bars mobile-nav__toggler"></span>
                    </div>
                    <div className="header-info">
                        <div className="header-info__box">
                            <i className="azino-icon-email1"></i>
                            <div className="header-info__box-content">
                                <h3>Email</h3>
                                <p>
                                    <a href={`mailto:${dbData.dbPrincipal.emails[0].email}`}>{dbData.dbPrincipal.emails[0].email}</a>
                                </p>
                            </div>
                        </div>
                        <div className="header-info__box">
                            <i className="azino-icon-calling"></i>
                            <div className="header-info__box-content">
                                <h3>Phone</h3>
                                <p>
                                    <a href={`tel:+1${dbData.dbPrincipal.phones[0].phone}`}>{dbData.dbPrincipal.phones[0].phone}</a>
                                </p>
                            </div>
                        </div>
                        <div className="header-info__box">
                            <i className="azino-icon-address"></i>
                            <div className="header-info__box-content">
                                <h3>Location</h3>
                                <p>
                                    <a href='#map'>{dbData.dbPrincipal.location[0].address}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <nav className="main-menu">
                <Container>
                    <NavsLink extraClassName="dynamic-radius"/>
                    <Link className="thm-btn inverseBtn dynamic-radius text-capitalize" to="/contact">
                        free estimate
                    </Link>
                </Container>
            </nav>
        </div>
    )
}

export default Header;